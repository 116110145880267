import router from "@/router/index.js";

const state = {
  userToken: localStorage.getItem('token') || null,
  tempUserToken: localStorage.getItem('tempToken') || null,
  authenticated: localStorage.getItem("token") !== null,
  userProfile: (() => {
    try {
      return JSON.parse(localStorage.getItem("userProfile"));
    } catch (e) {
      return undefined;
    }
  })(),
};


const getters = {
  getToken: (state) => state.userToken,
  getTempToken: (state) => state.tempUserToken,
  getUserProfile: (state) => state.userProfile
};

const mutations = {
  updateAuthenticated(state,{authenticated, userProfile, accessToken}){
    state.authenticated = authenticated;
    state.userProfile = userProfile;
    state.userToken = accessToken;

    if(accessToken !== null){
      localStorage.setItem('token',accessToken);
      localStorage.setItem('userProfile',JSON.stringify(userProfile));

      if(userProfile.role.name === 'user' || userProfile.role.name === 'trainer'){
        router.push({name: 'Dashboard'});
      }else{
        router.push({name: 'AdminHome'});
      }

    }else{
      localStorage.removeItem('token');
      localStorage.removeItem('userProfile');
      router.push({name: 'Login'});
    }
  },
  manualSetToken(state,accessToken){
    state.tempUserToken = accessToken;
    if(accessToken == null){
      localStorage.removeItem('tempToken');
    }else{
      localStorage.setItem('tempToken',accessToken);
    }
  },
  updateProfileDetails(state,profile){
    state.userProfile = profile;
    localStorage.setItem('userProfile',JSON.stringify(profile));
  }
};


const actions ={
};


export default {
	state,
	getters,
	mutations,
	actions,
};

