<template>
  <div id="app">
    <Loading :active.sync="isLoading" 
      :is-full-page="true"
      color="#c41230"
      :width="100"
      :height="100"
      :opacity="0.9"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import { eventBus } from './main.js'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading
  },
  data(){
    return{
      isLoading: null,
    }
  },
  created(){
    eventBus.$on('loader',(start) => {
      if(start){
        this.isLoading = true;
      }else{
        this.isLoading = false;
      }
    })
  }
}
</script>

<style scoped lang="scss">
/deep/ .vue-notification {
  padding: 20px;
  margin: 20px;

  .notification-title {
    font-size: 15px;
  }

  .notification-content {
    font-size: 13px;
  }
}

@media screen and (max-width:450px) {
  #app{
    max-height: 100vh;
  }
}

</style>