import Vue from "vue";
import Vuex from "vuex";

//Modules
import user from "./userStore";
import game from "./gameStore";
import leaderboard from "./leaderboardStore";

// API
import { staticLink } from '@/services/api/staticLink.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pdfState: false,
    staticLinks: []
  },
  getters: {
    getStaticLinks: (state) => state.staticLinks,
  },
  mutations: {},
  actions: {
    setPDFState({ state }, payload) {
      state.pdfState = payload;

      return payload;
    },
    async callStaticLinks({state}){

      const staticLinkAPI = await staticLink();

      state.staticLinks = staticLinkAPI.data.response.staticLinks;
    }
  },
  modules: {
    user,
    game,
    leaderboard,
  },
});
