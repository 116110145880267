import axios from "@/axios.config.js";

const getGameList = (gameType) => axios.get('/api/v1/games',null,{
  params:{
    type: gameType ?? 'PUBLIC'
  }
});

const gameSessionShow = (gameSessionId) => axios.get(`/api/v1/game_sessions/${gameSessionId}`);

const gameSessionStore = (password,gameId) => axios.post('/api/v1/game_sessions',password,{
  params:{
    game_id: gameId
  }
});

const gameSessionStart = (gameSessionId) => axios.post(`/api/v1/game_sessions/${gameSessionId}/start`);

const marketDataPoints = (gameSessionId) => axios.get(`/api/v1/game_sessions/${gameSessionId}/market_data_points`);

const latestMarketDataPoint = (gameSessionId) => axios.get(`/api/v1/game_sessions/${gameSessionId}/latest_market_data_point`);

const nextDay = (gameSessionId) => axios.post(`/api/v1/game_sessions/${gameSessionId}/next_day`);

const placeOrder = (gameSessionId,body) => axios.post(`/api/v1/game_sessions/${gameSessionId}/place_order`,body);

const gameSessionEnd = (gameSessionId) => axios.post(`/api/v1/game_sessions/${gameSessionId}/end`);

const gameLatestOrder = (gameSessionId) => axios.get(`/api/v1/game_sessions/${gameSessionId}/latestOrder`);

const orderIndex = (gameSessionId) => axios.get(`/api/v1/game_sessions/${gameSessionId}/orders`);

const gameReport = (gameSessionId) => axios.get(`/api/v1/game_sessions/${gameSessionId}/report`);

const gameEnd = (gameId) => axios.post(`/api/v1/games/${gameId}/end`);


export {
  getGameList,
  gameSessionShow,
  gameSessionStore,
  gameSessionStart,
  marketDataPoints,
  latestMarketDataPoint,
  nextDay,
  placeOrder,
  gameSessionEnd,
  gameLatestOrder,
  orderIndex,
  gameReport,
  gameEnd
}
