import { getGameList } from '@/services/api/game.js'

const state = {
  gameList: [],
  lastAPICalled: null,
  currentGame: (() => {
    try {
      return JSON.parse(localStorage.getItem("currentGame"));
    } catch (e) {
      return undefined;
    }
  })(),
  currentGameSession: (() => {
    try {
      return JSON.parse(localStorage.getItem("currentGameSession"));
    } catch (e) {
      return undefined;
    }
  })(),
};


const getters = {
  getGameList: (state) => state.gameList,
  getCurrentGame: (state) => state.currentGame,
  getCurrentGameSession: (state) => state.currentGameSession,
  getLastAPICalled: (state) => state.lastAPICalled,
};

const mutations = {
  setGameList : (state,payload) => {
    state.lastAPICalled = new Date().getTime();
    state.gameList = payload.games;
  },
  setCurrentGame : (state,payload) => {
    localStorage.setItem('currentGame',JSON.stringify(payload));
    state.currentGame = payload;
  },
  setCurrentGameSession: (state,payload) => {
    localStorage.setItem('currentGameSession',JSON.stringify(payload));
    state.currentGameSession = payload;
  }
};


const actions = {
  //Get List of Games
  async callGameList({commit}){
    try {
      const response = await getGameList();
      
      commit('setGameList',response.data.response);
      

    } catch (error) {
      
    }
  }
};


export default {
	state,
	getters,
	mutations,
	actions,
};

