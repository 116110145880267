<template>
  <div class="content-container">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .content-container{
    border-radius: 10px;
    background: $white;
    width: 100%;
    padding: 15px;

    @media screen and (max-width:450px){
      padding: 10px;
      border-radius: 0px !important;
      height: 100%;
      margin-bottom: 0;
    }

    @media screen and (max-width:768px){
      overflow: auto;
    }
  }
</style>