<template>
  <div :class="[{'full-height': fullHeight},'content-wrapper']">
    <div class="content-header">
      <img src="../assets/icons/burger-menu.png" alt="menu" @click="openSideBar" class="menu">
      <div class="content-title">
        <slot name="title"></slot>
      </div>
    </div>
    <slot></slot>
    <div class="legal-content">
      <span style="padding-left:0px"><a href="https://www.kenangafutures.com.my/disclaimer/" target="_blank">Disclaimer</a></span>
      <span><a href="https://www.kenangafutures.com.my/pdpa/" target="_blank">PDPA Notice</a></span>
      <span>Kenanga Futures Sdn Bhd 199501024398 (353603-X). All rights reserved.</span>
    </div>
  </div>
</template>

<script>
import {eventBus} from '../main';

export default {
  props:['title','fullHeight'],
  methods:{
    openSideBar(){
      eventBus.$emit('toggleSideBar');
    }
  }
}
</script>

<style lang="scss" scoped>

  .content-header{
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @media screen and (max-width:450px){
      padding: 15px;
      margin: 0;
      min-height: 60px;
    }

    .menu{
      width: 18px;
      height: 13px;
      margin-right: 15px;
      display: none;
      cursor: pointer;

      @media screen and (max-width:768px){
        display: initial;
      }
    }
  }

  .content-wrapper{
    background: linear-gradient(#C41230 70%,#414241);
    padding: 15px;
    width: 100%;
    overflow: auto;
    position: relative;
    margin-left: 200px;

    @media screen and (max-width: 450px){
      padding: 0px;
      overflow: initial;
    }

    @media screen and (max-width: 768px){
      margin-left: 0;
      height: calc(100vh - 58px);
    }
  }
  
  .full-height{
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .content-title{
    width: 100%;
    display: block;
    color: white;
    font-size: 23px;
    font-weight: bold;

    @media screen and (max-width: 768px){
      margin-bottom: 0px;
      margin: auto;
      font-size: 20px;
    }
  }

  .legal-content{
    padding: 10px 0;
    display: flex;

    @media screen and (max-width:600px){
      display: none;
    }

    span{
      padding-left: 20px;
      color: white;
      font-size: 12px;

      a{
        color: inherit;
      }

      &:last-of-type{
        margin-left: auto;
      }
    }
  }
</style>