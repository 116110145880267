import axios from "@/axios.config.js";

const getLeaderboard = (params, page) =>
  axios.get("/api/v1/leaderboards", {
    params: {
      year: params?.year,
      month: params?.month,
      week: params?.week,
      game_id: params?.game_id,
      page: page ?? 1,
      limit: params?.limit,
    },
  });

// {{url}}/api/v1/leaderboards/personal?page=1&limit=15&order=DATE&asc=0&q=test
const getPersonalLeaderboard = (params, page) =>
  axios.get(`/api/v1/leaderboards/personal`, {
    params: {
      page: page ?? 1,
      limit: params?.limit ?? 200,
      order: params?.order ?? "DATE",
      asc: params?.asc ?? 0,
      q: params?.q ?? "",
    },
  });

export { getLeaderboard, getPersonalLeaderboard };
