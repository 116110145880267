import { getLeaderboard, getPersonalLeaderboard } from '@/services/api/leaderboard.js'

const state = {
  leaderboardList: [],
  personalLeaderboardList: []
};


const getters = {
  getLeaderboardList: (state) => state.leaderboardList,
  getPersonalLeaderboardList: (state) => state.personalLeaderboardList,
};

const mutations = {
  setLeaderboardList : (state,payload) => {
    state.leaderboardList = payload.leaderboards;
  },
  setPersonalLeaderboardList: (state,payload) => {
    state.personalLeaderboardList = payload;
  }
};


const actions = {
  //Get List of Leaderboard
  async callLeaderboardList({commit},{params = null, page = 1}){
    try {
      const response = await getLeaderboard(params,page);
      
      commit('setLeaderboardList',response.data.response);
      

    } catch (error) {
      console.log(error);
    }
  },
  async callPersonalLeaderboardList({commit,dispatch},{params = null, page = 1,data = null}){
    try {
      const response = await getPersonalLeaderboard(params,page);

      if(response.data.response.leaderboards.length){

        if(data == null){
          dispatch('callPersonalLeaderboardList',{page: page + 1,params, data: [...response.data.response.leaderboards]})
        }else if(response.data.response.leaderboards.length < 200){
          commit('setPersonalLeaderboardList',data.concat(response.data.response.leaderboards));
        }
        else{
          dispatch('callPersonalLeaderboardList',{page: page + 1,params, data: data.concat(response.data.response.leaderboards)})
        }
        
      }else{
        commit('setPersonalLeaderboardList',data);
      }



    } catch (error) {
      console.log(error);
    }
  }
};


export default {
	state,
	getters,
	mutations,
	actions,
};

