import axios from "axios";
import store from "@/store/index.js"; //To get user token from vuex (state management)
import Swal from 'sweetalert2'
// import Vue from 'vue';
import 'sweetalert2/dist/sweetalert2.min.css';
// const vueInstance = Vue.use(Swal);

/*
  SET BASE URL FOR AXIOS from env file
*/
const instance = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
});


instance.interceptors.request.use(
  function(config) {
    const token = store.getters.getToken || store.getters.getTempToken;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  const token = store.getters.getToken || store.getters.getTempToken;
  if(token && error.response.data.errorCode === 703){

    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.response.data.userMessage
    }).then(async () => {
      await store.commit('updateAuthenticated',{
        authenticated : false,
        userProfile : null, 
        accessToken: null
      });
    });
  }
  return Promise.reject(error);
});


export default instance;
