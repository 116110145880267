<template>
    <button :disabled="disabled" @click="$emit('click')" v-if="!link" :class="[disabled ? 'disabled': '', mode]">
        <slot></slot>
    </button>
    <router-link v-else :to="to" :class="mode">
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            required: false,
            default: ''
        },
        link: {
            type: Boolean,
            required: false,
            default: false,
        },
        to: {
            type: String,
            required: false,
            default: '/'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
button,a{
    display: inline-block;
    border: none;
    background: var(--redC41230);
    color: var(--whiteFFFFFF);
    padding: 0.5rem 3rem;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    letter-spacing: 1px;
    @include respond(tab-land){
        padding: .4rem 2rem;
    }
    @include respond(phone){
        padding: .4rem 2rem;
        font-size: $twelve;
    }
}

.dark{
    background: var(--gray414241);
}

.flat {
  background-color: transparent;
  background: var(--redC41230);
  border: none;
}

.outline {
  background-color: transparent;
  border-color: var(--redC41230);
  color: #270041;
}

.disabled{
    opacity: .6;
    cursor: not-allowed;
}

.square{
    font-size: 18px;
    border-radius: 5px;
    padding: .3rem;
}

.admin-user{
    padding: .5rem 1rem;
    width: 100px;
}

.compact{
    padding: .5rem 1rem;
}

</style>