import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
    name: "Home",
    redirect: "dashboard/public-game",
    meta: {
      requiresAuth: true,
      userRoute:true
    },
		component: () => import(/* webpackChunkName: "Home" */ "@/modules/Home"),
		children: [
      {
        path: "dashboard",
        name: "Dashboard",
        redirect: "dashboard/public-game",
        component: () => import(/* webpackChunkName: "Dashboard"*/ "@/modules/Home/Dashboard"),
        children:[
          {
            path: '',
            name: 'GameDashboard',
            redirect: "public-game",
            component: () => import(/* webpackChunkName: "GameDashboard"*/ "@/modules/Home/Dashboard/GameDashboard"),
            children:[
              {
                path: "public-game",
                name: "PublicGameTab",
                component: () => import(/* webpackChunkName: "PublicGameTab"*/ "@/modules/Home/Dashboard/GameDashboard/PublicGame"),
              },
              {
                path: "private-game",
                name: "PrivateGameTab",
                component: () => import(/* webpackChunkName: "PrivateGameTab"*/ "@/modules/Home/Dashboard/GameDashboard/PrivateGame"),
              },
            ]
          },
          {
            path: "game",
            name: "GameRoom",
            redirect:'/dashboard',                                                                                                                  
            component: () => import(/* webpackChunkName: "GameRoom"*/ "@/modules/Home/Dashboard/GameRoom"),
            children:[
              {
                path: "public/:gameId",
                name: "PublicGame",
                component: () => import(/* webpackChunkName: "PublicGame"*/ "@/modules/Home/Dashboard/GameRoom/PublicGame"),
              },
              {
                path: "private/:gameId",
                name: "PrivateGame",
                component: () => import(/* webpackChunkName: "PrivateGame"*/ "@/modules/Home/Dashboard/GameRoom/PrivateGame"),
              },
              {
                path: "private/:gameId/trainer",
                name: "PrivateGameTrainer",
                component: () => import(/* webpackChunkName: "TrainerPrivateGame"*/ "@/modules/Home/Dashboard/GameRoom/AdminPrivateGame"),
              }
            ]
          },
          {
            path: "game",
            name: "Game",
            component: () => import(/* webpackChunkName: "TrainerCreateGame"*/ "@/modules/Home/Dashboard/Game"),
            children:[
              {
                path: 'create',
                name: 'TrainerCreateGame',
                component: () => import(/* webpackChunkName: "TrainerCreateGame"*/ "@/modules/Home/Dashboard/Game/CreateGame"),
              },
              {
                path: 'edit/:id',
                name:'TrainerEditGame',
                component: () => import(/* webpackChunkName: "TrainerEditGame"*/ "@/modules/Home/Dashboard/Game/EditGame"),
              }
            ]
          }
        ]
      },
      {
        path: "/leaderboard",
        name: "Leaderboard",
        component: () => import(/* webpackChunkName: "Leaderboard"*/ "@/modules/Home/Leaderboard"),
      },
      {
        path: "/leaderboard/:id",
        name: "LeaderboardDetails",
        component: () => import(/* webpackChunkName: "LeaderboardDetails"*/ "@/modules/Home/Leaderboard/LeaderboardDetails"),
      },
      {
        path: "/contract",
        name: "Contract",
        component: () => import(/* webpackChunkName: "Contract"*/ "@/modules/Home/Contract"),
      },
      {
        path: "/invite",
        name: "Invite",
        component: () => import(/* webpackChunkName: "Invite"*/ "@/modules/Home/Invite"),
      },
      {
        path: "/game-walkthrough",
        name: "GameWalkthrough",
        component: () => import(/* webpackChunkName: "GameWalkthrough"*/ "@/modules/Home/GameWalkthrough"),
      },
      {
        path: "/rules",
        name: "Rules",
        component: () => import(/* webpackChunkName: "Rules"*/ "@/modules/Home/Rules"),
      },
      {
        path: "/contact",
        name: "Contact",
        component: () => import(/* webpackChunkName: "Contact"*/ "@/modules/Home/Contact"),
      },
      {
        path: "/signout",
        name: "signout",
        component: () => import(/* webpackChunkName: "Signout"*/ "@/modules/Home/Signout"),
      },
      {
        path: "/profile",
        name: "Profile",
        redirect: '/profile/',
        component: () => import(/* webpackChunkName: "User"*/ "@/modules/Home/Profile"),
        children:[
          {
            path: '/',
            name: 'ViewProfile',
            component: () => import(/* webpackChunkName: "ViewProfile"*/ "@/modules/Home/Profile/viewProfile"),
          },
          {
            path: "edit",
            name: "EditProfile",
            component: () => import(/* webpackChunkName: "EditProfile"*/ "@/modules/Home/Profile/editProfile"),
          },
        ]
      },
      
      {
        path: "/profile/successfulReferrals",
        name: "Success",
        component: () => import(/* webpackChunkName: "SuccessReferrals"*/ "@/modules/Home/Profile/SuccessfulReferrals/index"),	
      }
		],
  },
	{
		path: "/user",
    name: "User",
    meta: {
      disallowAuth: true,
      adminRoute:false
    },
		component: () => import(/* webpackChunkName: "User"*/ "@/modules/User"),
		children: [
			{
				path: "login",
				name: "Login",
				component: () => import(/* webpackChunkName: "Signin"*/ "@/modules/User/Signin"),
			},
			{
				path: "register",
				name: "Register",
				component: () => import(/* webpackChunkName: "Signup"*/ "@/modules/User/Signup"),
			},
			{
				path: "reset-password",
				name: "ResetPassword",
				component: () => import(/* webpackChunkName: "ResetPassword"*/ "@/modules/User/ResetPassword"),
			},
      {
        path: 'reset-password-confirm/:email',
        name: 'ResetPasswordConfirm',
        component: () => import(/* webpackChunkName: "ResetPasswordConfirm"*/ "@/modules/User/ResetPasswordConfirm"),
      },
			{
				path: "create-new-password",
				name: "CreateNewPassword",
				component: () => import(/* webpackChunkName: "CreateNewPassword"*/ "@/modules/User/CreateNewPassword"),
			},
			{
				path: "verify-email/:email",
				name: "VerifyEmail",
        component: () => import(/* webpackChunkName: "VerifyEmail"*/ "@/modules/User/VerifyEmail"),
        props: true
			},
		],
  },
  {
    path: '/admin',
    name: 'AdminHome',
    redirect: '/admin/dashboard/public-game',
    meta:{
      adminRoute:true,
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "AdminHome"*/ "@/modules/Admin"),
    children:[
      {
        path: "dashboard",
        name: "AdminDashboard",
        redirect: "public-game",
        component: () => import(/* webpackChunkName: "AdminDashboard"*/ "@/modules/Home/Dashboard"),
        children:[
          {
            path: '',
            name: 'AdminGameDashboard',
            redirect: "public-game",
            component: () => import(/* webpackChunkName: "AdminGameDashboard"*/ "@/modules/Home/Dashboard/GameDashboard"),
            children:[
              {
                path: "public-game",
                name: "AdminPublicGameTab",
                component: () => import(/* webpackChunkName: "AdminPublicGameTab"*/ "@/modules/Home/Dashboard/GameDashboard/PublicGame"),
              },
              {
                path: "private-game",
                name: "AdminPrivateGameTab",
                component: () => import(/* webpackChunkName: "AdminPrivateGameTab"*/ "@/modules/Home/Dashboard/GameDashboard/PrivateGame"),
              },
            ]
          },
          {
            path: "game",
            name: "AdminGameRoom",
            redirect:'/dashboard',                                                                                                                  
            component: () => import(/* webpackChunkName: "AdminGameRoom"*/ "@/modules/Home/Dashboard/GameRoom"),
            children:[
              {
                path: "public/:gameId",
                name: "AdminPublicGame",
                component: () => import(/* webpackChunkName: "AdminPublicGame"*/ "@/modules/Home/Dashboard/GameRoom/PublicGame"),
              },
              {
                path: "private/:gameId",
                name: "AdminPrivateGame",
                component: () => import(/* webpackChunkName: "AdminPrivateGame"*/ "@/modules/Home/Dashboard/GameRoom/AdminPrivateGame"),
              },
            ]
          },
          {
            path: "game",
            name: "AdminGame",
            component: () => import(/* webpackChunkName: "AdminCreateGame"*/ "@/modules/Home/Dashboard/Game"),
            children:[
              {
                path: 'create',
                name: 'AdminCreateGame',
                component: () => import(/* webpackChunkName: "AdminCreateGame"*/ "@/modules/Home/Dashboard/Game/CreateGame"),
              },
              {
                path: 'edit/:id',
                name:'AdminEditGame',
                component: () => import(/* webpackChunkName: "AdminEditGame"*/ "@/modules/Home/Dashboard/Game/EditGame"),
              }
            ]
          }
        ]
      },
      {
        path: "leaderboard",
        name: "AdminLeaderboard",
        component: () => import(/* webpackChunkName: "AdminLeaderboard"*/ "@/modules/Home/Leaderboard"),
      },
      {
        path: "leaderboard/:id",
        name: "AdminLeaderboardDetails",
        component: () => import(/* webpackChunkName: "AdminLeaderboardDetails"*/ "@/modules/Home/Leaderboard/LeaderboardDetails"),
      },
      {
        path: 'contract',
        name: 'AdminContract',
        component: () => import(/* webpackChunkName: "AdminContract"*/ "@/modules/Home/Contract/Admin"),
      },
      {
        path: 'contract/new',
        name: 'CreateContract',
        component: () => import(/* webpackChunkName: "AdminContractNew"*/ "@/modules/Home/Contract/Admin/CreateContract"),
      },
      {
        path: 'contract/edit',
        name: 'EditContract',
        component: () => import(/* webpackChunkName: "AdminContractEdit"*/ "@/modules/Home/Contract/Admin/EditContract"),
      },
      {
        path: "invite",
        name: "AdminInvite",
        component: () => import(/* webpackChunkName: "AdminInvite"*/ "@/modules/Home/Invite"),
      },
      {
        path: "market-data",
        name: "MarketData",
        component: () => import(/* webpackChunkName: "AdminMarketData"*/ "@/modules/Admin/MarketData/MarketData"),
      },
      {
        path: "profile",
        name: "AdminViewUserProfile",
        redirect: '/admin/profile/',
        component: () => import(/* webpackChunkName: "ProfileAdmin"*/ "@/modules/Home/Profile"),
        children:[
          {
            path: '/',
            name: 'AdminViewProfile',
            component: () => import(/* webpackChunkName: "AdminViewProfile"*/ "@/modules/Home/Profile/viewProfile"),
          },
          {
            path: "edit",
            name: "AdminEditProfile",
            component: () => import(/* webpackChunkName: "AdminUserEdit"*/ "@/modules/Home/Profile/editProfile"),
          },
        ]
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "AdminUsersHome"*/ "@/modules/Admin/Users"),
        children:[
          {
            path: '',
            name: 'Users',
            component: () => import(/* webpackChunkName: "AdminUsers"*/ "@/modules/Admin/Users/adminUsers"),
          },
          {
            path: ':userId',
            name: 'IndividualUsers',
            component: () => import(/* webpackChunkName: "IndividualUsers"*/ "@/modules/Admin/Users/IndividualUsers/index.vue"),
            props: true
          },
          {
            path: ":userId/successfulReferrals",
            name: "SuccessRefAdmin",
            component: () => import(/* webpackChunkName: "SuccessReferralsAdmin"*/ "@/modules/Admin/Users/SuccessfulReferrals/index"),	
          }
        ]
      },
      {
        path: "game-walkthrough",
        name: "AdminGameWalkthrough",
        component: () => import(/* webpackChunkName: "AdminGameWalkthrough"*/ "@/modules/Home/GameWalkthrough"),
      },
      {
        path: "rules",
        name: "AdminRules",
        component: () => import(/* webpackChunkName: "AdminRules"*/ "@/modules/Home/Rules"),
      },
      {
        path: "signout",
        name: "AdminSignout",
        component: () => import(/* webpackChunkName: "AdminSignout"*/ "@/modules/Home/Signout"),
      },
      {
        path: "profile",
        name: "AdminProfile",
        component: () => import(/* webpackChunkName: "AdminProfile"*/ "@/modules/Home/Profile"),
      },
      {
        path: 'feedback',
        name: 'AdminFeedback',
        component: () => import(/* webpackChunkName: "AdminFeedback"*/ "@/modules/Home/Feedback")
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
  const token = store.getters.getToken;
  const userRole = store.getters.getUserProfile?.role.name;
  if (to.matched.some(m => m.meta.requiresAuth)) {
    if (token !== null) {
      if((to.name == 'TrainerCreateGame' || to.name == 'PrivateGameTrainer') && (userRole === 'admin' || userRole === 'user')){
        next({name: 'Dashboard'})
      }
      else if(to.matched.some(m => m.meta.adminRoute) && (userRole === 'user' || userRole === 'trainer')){
        next({name: "Dashboard"})
      }else if(to.matched.some(m => m.meta.userRoute) && userRole === 'admin'){
        next({name: 'AdminHome'})
      }
      else{
        next();
      }
    } else {
      next({ name: "Login" });
    }
  } else if (to.matched.some(m => m.meta.disallowAuth) && token !== null) {
    next({ name: "Home" });
  } else {
    next();
  }
});

export default router;
