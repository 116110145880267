import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/main.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEyeSlash, faEye, faCheck, faLock, faChevronRight, faUserCircle, faSort, faSearch, faCheckCircle, faInfoCircle, faEdit, faDownload, faPlusCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {extend,ValidationObserver,ValidationProvider} from 'vee-validate/dist/vee-validate.full';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueMeta from 'vue-meta'

import ContentWrapper from '@/components/ContentWrapper';
import Container from '@/components/Container';

import BaseButton from "@/components/BaseButton"

import VueGtag from "vue-gtag";

import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css') 

//Font awesome
library.add(faEyeSlash);
library.add(faEye);
library.add(faCheck);
library.add(faLock);
library.add(faChevronRight);
library.add(faUserCircle);
library.add(faSort);
library.add(faSearch);
library.add(faCheckCircle);
library.add(faInfoCircle);
library.add(faEdit);
library.add(faDownload);
library.add(faPlusCircle);
library.add(faCircle);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Vue Gtag
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID },
  appName: process.env.VUE_APP_GTAG_APP_NAME,
  pageTrackerScreenviewEnabled: true
}, router);

//Vue Meta
Vue.use(VueMeta);

//Vue select
Vue.component("v-select", vSelect);

// Vue Table
Vue.use(VueGoodTablePlugin);

//Vee validate
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Vue Tour
Vue.use(VueTour)

extend('verify_password', {
  validate: value => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      return strongRegex.test(value);
  },
  message: `Password must contain at least: 1 uppercase character, 1 lowercase character, 1 number, and 1 special character (i.e: $, #, @, !).`,
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password does not match'
});

//Global components
Vue.component('ContentWrapper',ContentWrapper);
Vue.component('Container',Container);
Vue.component('BaseButton',BaseButton)

//Event Bus
export const eventBus = new Vue();

const options = {
  confirmButtonColor: '#c41230',
}

//Sweetalert
Vue.use(VueSweetalert2,options);

Vue.config.productionTip = false;

//Custom Directives
Vue.directive('click-outside', {
  bind () {
      this.event = event => this.vm.$emit(this.expression, event)
      this.el.addEventListener('click', this.stopProp)
      document.body.addEventListener('click', this.event)
  },   
  unbind() {
    this.el.removeEventListener('click', this.stopProp)
    document.body.removeEventListener('click', this.event)
  },

  stopProp(event) { event.stopPropagation() }
})

//Custom Filter
Vue.filter('moneyFormat', function (value) {
  if (!value) return ''
  var formatter = new Intl.NumberFormat('en-US',{
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
})

//Add Global Mixins
Vue.mixin({
  data: function() {
    return {
      get userRole() {
        const userRole = store.getters.getUserProfile?.role.name;
        return userRole
      },
      get userProfileData(){
        const userProfile = store.getters.getUserProfile;
        return userProfile
      }
    }
  },
  methods:{
    timeDifference(date1,date2) {
      var difference = date1 - date2;
  
      var daysDifference = Math.floor(difference/1000/60/60/24);
      difference -= daysDifference*1000*60*60*24
  
      var hoursDifference = Math.floor(difference/1000/60/60);
      difference -= hoursDifference*1000*60*60
  
      var minutesDifference = Math.floor(difference/1000/60);
      difference -= minutesDifference*1000*60
  
      var secondsDifference = Math.floor(difference/1000);
  
      return{
        minutePast: minutesDifference,
        secondPast: secondsDifference
      }
    },
    async checkLastAPICall(){
      const lastAPICalled = this.$store.getters.getLastAPICalled;
      if(lastAPICalled != null){
        const difference = this.timeDifference(new Date().getTime(),lastAPICalled)
        if(difference.minutePast >= 5){
          eventBus.$emit('loader',true);
          await this.$store.dispatch('callGameList');
          eventBus.$emit('loader',false);

        }
      }
    }
  }
})

//Array prototype 
Array.min = function( array ){
  return Math.min.apply( Math, array );
};

Array.max = function( array ){
  return Math.max.apply( Math, array );
};

console.log('mode',process.env.NODE_ENV);

// const prodAuth = localStorage.getItem('prodAuth');

// if(process.env.NODE_ENV === 'production' && !prodAuth){
//   const pw = "a2VuYW5nYWZ1dHVyZXNiNjQ="
//   const promptPw = prompt("Password");
//   if(!promptPw || btoa(promptPw) !== pw) throw "Incorrect password";

//   localStorage.setItem('prodAuth',true);
// }

moment.locale('en-my');

new Vue({
	router,
	store,
  metaInfo(){
    return{
      title: 'Virtual Trading by Kenanga Futures',
      meta: [
        { name: 'description', content:  'A simulated trading environment that provides hands-on trading experience on how listed derivatives trading works before committing your real capital in this market.'},
        { property: 'og:title', content: "Virtual Trading by Kenanga Futures"},
        { property: 'og:url', content: process.env.VUE_APP_LINK},
        { property: 'og:description', content: "A simulated trading environment that provides hands-on trading experience on how listed derivatives trading works before committing your real capital in this market."},
        { property: 'og:type', content: 'website'},    

        { property: 'twitter:card', content: "summary_large_image"},
        { property: 'twitter:url', content: process.env.VUE_APP_LINK},
        { property: 'twitter:title', content: "Virtual Trading by Kenanga Futures"},
        { property: 'twitter:description', content: "A simulated trading environment that provides hands-on trading experience on how listed derivatives trading works before committing your real capital in this market."},
        { property: 'twitter:type', content: 'website'},    
      ]
    }
  },
  render: (h) => h(App)
}).$mount("#app");
